import React from "react"
import PropTypes from "prop-types"

import Headroom from "react-headroom"

// Header icon in modal menu is seperate

const Header = props => {
  return (
    <div id="navElement" className="fixed z-20 min-w-full sm:min-w-full">
      <Headroom
        downTolerance={15}
        wrapperStyle={{ maxHeight: "0px", maxWidth: "0px" }}
      >
        <button
          className="min-w-0 focus:outline-none sm:bg-transparent"
          onClick={props.onToggle}
          aria-label="open menu"
        >
          {!props.isOpen && (
            <svg
              className="flex items-center justify-center w-8 h-8 mt-2 mb-2 ml-2 mr-2 text-cb-900 lg:mt-5 lg:mb-5 lg:ml-5 lg:mr-5 sm:w-10 sm:h-10 3xl:w-12 3xl:h-12 focus:outline-none"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=""
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 8h16M4 16h16"
              />
            </svg>
          )}
        </button>
      </Headroom>
    </div>
  )
}

export default Header

Header.propTypes = {
  onToggle: PropTypes.func.isRequired,
}
