import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function Footer() {
  return (
    <div className="flex flex-col w-full">
      <hr className="self-center flex-auto w-3/4 bg-gray-900" />
      <footer className="flex flex-row justify-center w-full bg-mbSnow">
        <div className="flex flex-row justify-around w-full max-w-screen-xl px-2 pt-8 pb-8 border-t border-white cantarell footer-columns lg:pt-16 lg:pb-16">
          <div className="sitemap">
            <h2 className="mb-2 text-sm font-medium text-gray-700 cantarell lg:text-lg 3xl:text-xl">
              Sitemap
            </h2>
            <nav
              role="navigation"
              className="flex flex-col gap-y-0.5 text-sm text-gray-700 cantarell lg:text-base 3xl:text-xl"
            >
              <div className="z-50 inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                <AniLink
                  className="py-0.5 pr-0.5"
                  to="/"
                  cover
                  direction="left"
                  bg="#FFFBFA"
                >
                  Home
                </AniLink>
              </div>
              {typeof window !== `undefined` &&
                (window.location.pathname === "/" ? (
                  <div className="inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                    <AnchorLink
                      className="py-0.5 pr-0.5 focus:outline-none"
                      to="/#about"
                    >
                      About
                    </AnchorLink>
                  </div>
                ) : (
                  <div className="inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                    <AniLink
                      className="py-0.5 pr-0.5"
                      to="/#about"
                      cover
                      direction="left"
                      bg="#FFFBFA"
                    >
                      About
                    </AniLink>
                  </div>
                ))}
              {typeof window !== `undefined` &&
                (window.location.pathname === "/" ? (
                  <div className="inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                    <AnchorLink
                      className="focus:outline-none py-0.5 pr-0.5"
                      to="/#contact"
                    >
                      Contact
                    </AnchorLink>
                  </div>
                ) : (
                  <div className="inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                    <AniLink
                      className="py-0.5 pr-0.5"
                      to="/#contact"
                      cover
                      direction="left"
                      bg="#FFFBFA"
                    >
                      Contact
                    </AniLink>
                  </div>
                ))}

              <AniLink to="/newsletter" cover direction="left" bg="#FFFBFA">
                <div className="inline-block py-0.5 pr-0.5 font-light transition-colors duration-75 ease-in hover:text-cb-900">
                  Newsletter
                </div>
              </AniLink>
              <AniLink to="/clips" cover direction="left" bg="#FFFBFA">
                <div className="inline-block py-0.5 pr-0.5 font-light transition-colors duration-75 ease-in hover:text-cb-900">
                  Clips
                </div>
              </AniLink>
            </nav>
          </div>
          <div className="legal">
            <h2 className="mb-2 text-sm font-medium text-gray-700 cantarell lg:text-lg 3xl:text-xl">
              Legal
            </h2>
            <nav
              role="navigation"
              className="flex flex-col gap-y-0.5 text-sm text-gray-700 cantarell lg:text-base 3xl:text-xl"
            >
              <div className="z-50 inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                <AniLink
                  className="py-0.5 pr-0.5"
                  to="/legal-notice"
                  cover
                  direction="left"
                  bg="#FFFBFA"
                >
                  Legal Notice
                </AniLink>
              </div>
              <div className="z-50 inline-block font-light transition-colors duration-75 ease-in hover:text-cb-900">
                <AniLink
                  className="py-0.5 pr-0.5"
                  to="/privacy-policy"
                  cover
                  direction="left"
                  bg="#FFFBFA"
                >
                  Privacy
                </AniLink>
              </div>
            </nav>
          </div>
          <div className="platforms">
            <h2 className="mb-2 text-sm font-medium text-gray-700 cantarell lg:text-lg 3xl:text-xl">
              Platforms
            </h2>
            <nav
              role="navigation"
              className="flex flex-col gap-y-0.5 text-sm text-gray-700 cantarell lg:text-base 3xl:text-xl"
            >
              <div>
                <a
                  className="py-0.5 pr-0.5 font-light transition-colors duration-75 ease-in hover:text-cb-900"
                  href="https://www.linkedin.com/in/paige-mcclanahan-282793a/"
                >
                  LinkedIn
                </a>
              </div>
              <div>
                <a
                  className="py-0.5 pr-0.5 font-light transition-colors duration-75 ease-in hover:text-cb-900"
                  href="https://www.instagram.com/paigemcclanahan/"
                >
                  Instagram
                </a>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    </div>
  )
}
