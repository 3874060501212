import React from "react"
import PropTypes from "prop-types"
import ReactModal from "react-modal"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { AnchorLink } from "gatsby-plugin-anchor-links"
// Keep other imports as they are
import cx from "classnames"

ReactModal.setAppElement("#___gatsby")

const ModalMenu = ({ isOpen, items, onToggle }) => {
  // Keep your other logic and functions as they are

  // Ensuring we safely access window object
  const currentPath =
    typeof window !== "undefined" ? window.location.pathname : ""

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Modal Fullscreen Site Menu"
      onAfterClose={() => {
        /* Your logic here */
      }}
      onAfterOpen={() => {
        /* Your logic here */
      }}
      parentSelector={() => document.getElementById("___gatsby")}
      closeTimeoutMS={0}
      className="fixed z-50 grid w-full h-screen max-h-screen overflow-hidden bg-cb-500 auto-cols-min auto-rows-min"
      style={{
        overlay: {
          height: "100%",
        },
        content: {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <button
        aria-label="close menu"
        onClick={onToggle}
        className="mt-2 mb-2 ml-2 mr-2 lg:mt-5 lg:mb-5 lg:ml-5 lg:mr-5 focus:outline-none"
        ariaLabel="close menu"
      >
        <svg
          className="w-8 h-8 transition duration-500 text-cb-900 ssm:w-10 sm:h-10 3xl:w-12 3xl:h-12 group-hover:text-cb-500 focus:outline-none"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className=""
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <nav
        role="navigation"
        className="flex flex-col items-start col-start-2 row-start-2 row-end-2 text-4xl text-white sm:leading-none lg:leading-none 2xl:leading-none 3xl:leading-none cantarell sm:text-5xl md:text-5xl lg:text-5xl 2xl:text-6xl 3xl:text-6xl max-h-3/4"
      >
        {items.map(item => {
          let isActive = currentPath === item.link.url // Determine if the current item is "active"
          return currentPath === "/" ? (
            <div
              className={cx(
                "inline-block -mb-1 transition-colors duration-75 ease-in hover:text-cb-900",
                { "text-cb-300": isActive }
              )}
            >
              <AnchorLink
                to={item.link.url}
                className="max-h-full"
                onAnchorLinkClick={onToggle}
                activeClassName="text-cb-300"
              >
                {item.text.text}
              </AnchorLink>
            </div>
          ) : (
            <div className="inline-block -mb-1 transition-colors duration-75 ease-in hover:text-cb-900">
              <AniLink
                to={item.link.url}
                className="max-h-full"
                activeClassName="text-cb-300"
                cover
                direction="left"
                bg="#FFFBFA"
                exit={{
                  zIndex: 1500, // exit default is 0
                }}
                onClick={onToggle}
              >
                {item.text.text}
              </AniLink>
            </div>
          )
        })}
      </nav>
    </ReactModal>
  )
}

ModalMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

export default ModalMenu
