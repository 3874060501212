import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import CookieConsent from "react-cookie-consent"
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

import ModalMenu from "./modalmenu"
import Header from "./header"
import Footer from "./footer"
// import SEO from "../components/seo"

export default function Layout({ children, description, title, data }) {
  const location = useLocation()
  const {
    prismicSiteSettings: {
      data: { main_menu_links: mainMenuLinks },
    },
  } = data

  const [isOpen, toggleOpen] = useState(false)

  function toggleModal() {
    toggleOpen(!isOpen)
  }

  return (
    <>
      {/* <Layout><SEO title={title} description={description} /> */}
      {location.pathname !== "/subscribe/" && (
        <ModalMenu
          isOpen={isOpen}
          onToggle={toggleModal}
          items={mainMenuLinks}
        />
      )}
      {location.pathname !== "/subscribe/" && (
        <Header isOpen={isOpen} onToggle={toggleModal} />
      )}
      {children}
      {location.pathname !== "/subscribe/" && <Footer />}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        className="cookie-banner-style"
        containerClasses="cookie-banner-container"
        buttonClasses="cookie-banner-accept-button"
        enableDeclineButton
        declineButtonClasses="cookie-banner-decline-button"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          // alert("Accept was triggered by clicking the Accept button")
          initializeAndTrack(location)
        }}
        expires={150}
        // onDecline={() => {
        //   alert("Declined")
        // }}
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="550"
        data-sal-easing="ease"
      >
        <p className="text-sm text-gray-900 text-light">
          {data.prismicSiteSettings.data.cookie_banner_text.text}
        </p>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
}
